import React, { useMemo } from 'react';
import styles from './style.module.scss';

import type { SeoLinkData } from 'data/seo-links';

interface ProvinceCitySeoLinksProps {
  seoLinks: SeoLinkData;
}

function ProvinceCitySeoLinks({ seoLinks }: ProvinceCitySeoLinksProps) {
  const linkElements = useMemo(() => {
    return seoLinks?.links.map((link, index) => (
      <div key={index} className={styles.item}>
        <a href={link.link}>{link.name}</a>
      </div>
    ));
  }, [seoLinks]);

  return (
    <div className={styles.component}>
      <h2 className={styles.title}>
        {seoLinks?.title}
      </h2>
      <div className={styles.list}>
        {linkElements}
      </div>
    </div>
  );
}

export default React.memo(ProvinceCitySeoLinks);
